import logo from './images/schaatsbaan-logo-drenthe-wit.png';
import angLogo from './images/ang-logo.png';
import './App.css';
import Newsletter from './components/newsletter/Newsletter';

function App() {
  return (
      <div className='container-fluid p-0'>
        <div className='App-background'>
            <img className='App-logo' src={logo} alt='Schaatsbaan Drenthe'/>
            <Newsletter/>
            <div className="down-arrow"></div>
        </div>
        <div className="body-container">
            <div className="text-container">
              <div className="article">
                <h1 className='page-title'>Het plan voor Schaatsbaan Drenthe</h1>
                <p>
                  Het realiseren van Schaatsbaan Drenthe biedt een unieke kans om de regio te voorzien van een multifunctionele (sport)locatie die jaarrond gebruikt kan worden. Dit project combineert (breedte)sport, recreatie en economische impuls, en streeft naar duurzaamheid en toegankelijkheid voor alle inwoners van Drenthe.
                </p>
                <p>
                  <b>Faciliteiten</b> <br/>
                  Schaatsbaan Drenthe zal, kort samengevat, bestaan uit de volgende onderdelen:
                </p>
                <ul>
                <li>Een volwaardige 400-meterbaan met tunneloverkapping, naar ISU-maatstaven</li>
                  <li>Volwaardige 60x30-baan tbv (fun)schaatsen, kunstschaatsen, shorttrack en ijshockey</li>
                  <li>Restaurant met winterplein, terrassen en sanitaire voorzieningen</li>
                  <li>Entreehal inclusief schaatsverhuur </li>
                  <li>Alle faciliteiten zijn volledig overdekt</li>
                </ul>
                <p>
                  <b>Laagdrempelig voor iedereen</b> <br/>
                  De schaatsbaan is toegankelijk voor iedereen, ongeacht leeftijd, afkomst of vaardigheidsniveau. Met betaalbare prijzen, goede bereikbaarheid en diverse activiteiten, wil Schaatsbaan Drenthe alle lagen van de samenleving verwelkomen.
                </p>
                <p>
                  <b>Gratis schoolschaats-programma</b> <br/>
                  Het plan omvat tien jaar gratis entree voor schoolschaatsen voor alle Drentse basis- en middelbare scholieren, met jaarlijks 40.000 bezoeken. Kinderen krijgen een terug-kom-kaartje voor een extra gratis bezoek, wat de jeugd stimuleert om te schaatsen en een gezonde levensstijl bevordert.
                </p>
                <p>
                  <b>Energie & duurzaamheid</b> <br/>
                  Schaatsbaan Rotterdam verbruikt dankzij de wind- en waterdichte tunnel jaarlijks ±700.000kWh, dat is gemiddeld 60% minder energie t.o.v. conventionele ijsbanen. Voor Schaatsbaan Drenthe wordt een verbruik van 900.000 kWh verwacht door de langere openingsperiode. Schaatsbaan Drenthe streeft naar energieneutraliteit met zonnepanelen en hergebruik van restwarmte. Hoewel er nog onduidelijkheden zijn over netcapaciteit en locatie, is de benodigde ruimte al gereserveerd en wordt een businesscase snel ontwikkeld zodra er meer duidelijkheid is.
                </p>
                <p>
                  <b>Multifunctionaliteit</b> <br/>
                  Schaatsbaan Drenthe biedt een ijsoppervlakte van 6200 m² voor diverse schaatsdisciplines zoals langebaanschaatsen, curling, shorttrack, ijshockey en kunstschaatsen in de winter. Het dient verschillende doelgroepen, van recreatieve tot verenigingsgebonden schaatsers, en bedrijven. In de zomer wordt de faciliteit omgevormd voor sporten, evenementen en parkeren voor TT Assen.
                </p>
                <p>
                  <b>Financiële onafhankelijkheid</b> <br/>
                  Na een eenmalige bijdrage in de stichtingskosten zal Schaatsbaan Drenthe operationeel en financieel volledig onafhankelijk functioneren. Dit houdt in dat de exploitatiekosten gedekt worden door de gegenereerde inkomsten uit onder andere entreegelden, schaatsverhuur, horeca en andere mogelijke commerciële activiteiten.
                </p>
                <p>
                  <b>Openingsperiode Schaatsbaan Drenthe</b> <br/>
                  Indien het besluit tot realisatie in 2024 wordt genomen, kan de schaatsbaan in Q4 van 2025 operationeel zijn. De schaatsbaan zal naar verwachting jaarlijks geopend zijn van de herfstvakantie tot en met de voorjaarsvakantie.
                </p>
                <p>
                  <b>Economische Impact</b><br/>
                  Schaatsbaan Drenthe zal de lokale economie stimuleren door werkgelegenheid te creëren en lokale bedrijven te betrekken. Het zal een verbindende rol spelen in de regionale zakelijke markt, vergelijkbaar met Schaatsbaan Rotterdam.
                </p>
              </div>
            </div>
            <div className='photos-block'>
              <div className='container'>
                <div className='row'>
                  <div className='col-md-6 col-sm-12'>
                    <div className='photos-item photos-item-1'>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='photos-item photos-item-2'>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='photos-item photos-item-3'>
                    </div>
                  </div>
                  <div className='col-md-6 col-sm-12'>
                    <div className='photos-item photos-item-4'>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className='blue-gradient-up'></div>
            <div className='blue-gradient-down'></div>
        </div>
        <div className='faq-container'>
          <div className='faq-left-segment'>
            <div className='faq-left-block'>
              <div className='faq-left-wrapper'>
                <h1 className='faq-left-title'>
                  FAQ
                </h1>
                <p className='faq-text'>
                  Hier vind je veel voorkomende vragen. Staat jouw vraag er niet bij? Neem dan contact met ons op met <a className='faq-link' href='mailto:info@schaatsbaandrenthe.nl'>info@schaatsbaandrenthe.nl</a>.
                </p>
              </div>
            </div>
          </div>
          <div className='faq-right-segment'>
            <div className='faq-right-block'>
              <div className='faq-list'>
                <div className='faq-list-items'>
                  <h1 className='faq-list-title'>FAQ</h1>
                  <div className='faq-list-item'>
                    <h4>Wat is Schaatsbaan Drenthe?</h4>
                    <p>
                      Schaatsbaan Drenthe is een project om een multifunctionele sportlocatie te realiseren in Drenthe, met een ijsoppervlakte van 6200 m². Het is bedoeld voor verschillende schaatsdisciplines zoals langebaanschaatsen, curling, shorttrack, ijshockey en kunstschaatsen in de winter, en diverse andere activiteiten in de zomer.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Is Schaatsbaan Drenthe een ‘volwaardige’ schaatsbaan?</h4>
                    <p>
                      Absoluut! Ondanks dat de opzet onconventioneel en vernieuwend is, zal de uitvoering voor alle soorten ijssport-liefhebbers meer dan herkenbaar zijn. We gaan een volwaardige ijsvloer (400-meterbaan & 60x30-baan) naar ISU-maatstaven realiseren en streven daarbij naar topkwaliteit. De schaatsbaan zal alle soorten bezoekers, van sporters tot toeschouwers, een kwalitatief hoogwaardige ervaring bieden.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Voor wie is Schaatsbaan Drenthe bedoeld?</h4>
                    <p>
                      De schaatsbaan is toegankelijk voor iedereen, ongeacht leeftijd, afkomst of vaardigheidsniveau. Met betaalbare prijzen, goede bereikbaarheid en een breed scala aan activiteiten wil Schaatsbaan Drenthe alle lagen van de samenleving verwelkomen.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Wanneer zal Schaatsbaan Drenthe openen?</h4>
                    <p>
                      Indien het besluit tot realisatie in 2024 wordt genomen, kan de schaatsbaan in het vierde kwartaal van 2025 operationeel zijn. De schaatsbaan zal jaarlijks geopend zijn van de herfstvakantie tot en met de voorjaarsvakantie.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Kan Schaatsbaan Drenthe rendabel worden?</h4>
                    <p>
                      Na een eenmalige bijdrage in de stichtingskosten zal Schaatsbaan Drenthe financieel en operationeel volledig onafhankelijk functioneren. De schaatsbaan wordt met deze bijdrage dus rendabel waardoor er jaarlijks géén gemeenschapsgeld naar toe hoeft. 
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Wat houdt het gratis schoolschaatsen in?</h4>
                    <p>
                      Als tegenprestatie voor de gevraagde bijdrage van de overheid biedt Schaatsbaan Drenthe (minimaal) tien jaar gratis entree voor schoolschaatsen aan alle basisschool- en middelbare scholieren in Drenthe. Jaarlijks zijn er 40.000 bezoeken mogelijk. Na afloop ontvangen de kinderen een terug-kom-kaartje voor een extra gratis bezoek, wat de jeugd stimuleert om te schaatsen en een gezonde levensstijl bevordert.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Hoe draagt Schaatsbaan Drenthe bij aan duurzaamheid?</h4>
                    <p>
                      Schaatsbaan Drenthe streeft naar energieneutraliteit door het installeren van zonnepanelen en hergebruik van restwarmte. De energie-efficiënte tunnelconstructie zal helpen om het energieverbruik te minimaliseren.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Wat is de economische impact van Schaatsbaan Drenthe?</h4>
                    <p>
                      De komst van Schaatsbaan Drenthe zal de lokale economie stimuleren door werkgelegenheid te creëren en lokale bedrijven te betrekken. Het zal een verbindende rol spelen in de regionale zakelijke markt en nieuwe relaties en zakelijke kansen bevorderen.
                    </p>
                  </div>
                  <div className='faq-list-item'>
                    <h4>Waar kan ik terecht als ik nog meer vragen heb?</h4>
                    <p>
                      Stuur een mail naar <a href='mailto:info@schaatsbaandrenthe.nl'>info@schaatsbaandrenthe.nl</a> en we proberen jouw vraag zo snel mogelijk te beantwoorden! Als je je inschrijft voor de nieuwsbrief houden we je op de hoogte van alle ontwikkelingen rondom de realisatie van Schaatsbaan Drenthe. 
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className='footer-block'>
          <div className='footer-wrapper'>
            <div className='footer-top w-clearfix'>
              <div className='footer-brand-wrapper'>
                <a href="https://schaatsbaandrenthe.nl" className="footer-brand w-inline-block">
                  <img src={logo} loading="lazy" alt="" className="footer-brand-img" />
                </a>
              </div>
              <div className='footer-nieuwsbrief-wrapper'>
                <div className='footer-nieuwsbrief-form-block'>
                  <div className='ogo-text-wrapper'>
                    <div className='logo-border'></div>
                    <span className='logo-footer-text'>part of A Nice Group</span>
                  </div>
                </div>
              </div>
              <div className='footer-links-wrapper'>
                <div className='footer-links-wrap'>
                  <a href='https://anice.group' className='footer-link'>A Nice Group</a>
                  <a href='https://schaatsbaanrotterdam.nl' className='footer-link'>Schaatsbaan Rotterdam</a>
                </div>
                <div className='footer-links-wrap'>
                  
                </div>
                <div>
                  <a href='https://anice.group'>
                    <img src={angLogo} alt='A Nice Group' className='ang-footer-logo'/>
                  </a>

                </div>
              </div>
            </div>
            <div className='footer-bot w-clearfix'>
              <div className="footer-info-col w-clearfix">
                <a href="tel:0107900514" className="footer-info-link margin-5">010 790 0514</a>
                <a href="mailto:info@schaatsbaandrenthe.nl" className="footer-info-link">info@schaatsbaandrenthe.nl</a>
              </div>
            </div>
          </div>
        </div>
      </div>
  );
}

export default App;
