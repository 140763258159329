import { faArrowRight } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState } from 'react';
import moment from 'moment';
import axios from 'axios';

const Newsletter = () => {
    const [newsletterData, setNewsletterData] = useState({
        email: '',
    });

    const [isSubmitted, setIsSubmitted] = useState(false);

    const handleInputChange = (e) => {
        const { name, value } = e.target;
        setNewsletterData({
          ...newsletterData,
          [name]: value,
        });
      };

    const handleSubmit = (e) => {
        e.preventDefault();

        let data = {
            Emailadres: newsletterData.email,
            Nieuwsbrief: true,
            AangemaaktOp: moment().format(),
            Vestiging: 'Drenthe'
        }
        const token = process.env.REACT_APP_CM_TOKEN;
        axios.post("https://api.cm.com/events/v1.0/tenants/adf4c185-7e43-4a27-9d6a-3677f2dde26e/events/3e0b6285-1311-4d0a-a12f-aa9d795c15ca", JSON.stringify(data), 
        { 
            headers: { 
                "X-CM-PRODUCTTOKEN": token, 
                "Content-Type": "application/json", 
            } 
        })
        .then(() => {
            setIsSubmitted(true);
        })
        .catch(err => {
            console.log(err);
        });
    };

    return (
        <div className='Newsletter-block'>
                <div className='row'>
                    <div className='col-sm-12 col-md-6'>
                        <div className='Newsletter-text align-middle'>
                            Schrijf je in voor onze nieuwsbrief voor de laatste updates!
                        </div>
                    </div>
                    <div className='col-sm-12 col-md-6'>
                        {isSubmitted ? (
                            <div className='Newsletter-input-box'>
                                <p className='Newsletter-success-message'>Bedankt voor de aanmelding!</p>
                            </div>
                        ) : (
                            <form onSubmit={handleSubmit}>
                                <div className='Newsletter-input-box'>
                                    <input name='email' type='email' required className='Newsletter-input' placeholder='e-mailadres' onChange={handleInputChange}/>
                                    <button className='Newsletter-button'><FontAwesomeIcon icon={faArrowRight}/></button>                                
                                </div>
                            </form>
                        )}
                    </div>
                </div>
        </div>
    );
    
}

export default Newsletter;